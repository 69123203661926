class Aailabilities{
    static instance;

    static getInstance() {
        if (!Aailabilities.instance) {
            Aailabilities.instance = new Aailabilities();
        }
        return Aailabilities.instance;
    }

    constructor(){
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init(){
        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-availabilities.default', ( $scope ) => {

            $scope.find('.availability-content-item').magnificPopup({
                delegate : 'a.view-photo',
                type     : 'image',
                tLoading : 'Loading image #%curr%...',
                mainClass: 'mfp-img-mobile',
                gallery  : {
                    enabled           : true,
                    navigateByImgClick: true,
                    preload           : [0, 1]
                },
                image    : {
                    tError  : '<a href="%url%">The image #%curr%</a> could not be loaded.',
                    titleSrc: function (item) {
                        return item.el.attr('title');
                    }
                },
            });

            if ($(window).width() > 992) {
                $scope.find('.scrollbar-inner').scrollbar();
            }

            $(window).resize(() => {
                if ($(window).width() > 992) {
                    $scope.find('.scrollbar-inner').scrollbar();
                } else {
                    $scope.find('.scrollbar-inner').scrollbar('destroy');
                }
            });

            let $row = $scope.find('.availability-content-item'),
                $collate = $scope.find('.availability-image-item');
            $row.on('click', function () {
                let data = $(this).data('focus');
                $row.removeClass('active');
                $(this).addClass('active');
                $collate.removeClass('active');
                $scope.find(data).addClass('active');

            });

            $scope.find('a.contactform-button').magnificPopup({
                type: 'inline',
                removalDelay: 500,
                callbacks: {
                    beforeOpen: function () {
                        this.st.mainClass = this.st.el.attr('data-effect');
                    }
                },
                midClick: true
            });
        } );
    }
}
Aailabilities.getInstance();