class Elementor_Template_Carousel {
    static instance;

    static getInstance() {
        if (!Elementor_Template_Carousel.instance) {
            Elementor_Template_Carousel.instance = new Elementor_Template_Carousel();
        }
        return Elementor_Template_Carousel.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            elementorFrontend.hooks.addAction('frontend/element_ready/opal-template_carousel.default', ($scope) => {
                Elementor_Template_Carousel.getInstance().enableSwiper($scope);
                $(window).resize(function () {
                    Elementor_Template_Carousel.getInstance().enableSwiper($scope);
                })
            });
        });
    }

    enableSwiper($scope) {
        let $carousel = $('.swiper-container', $scope);
        if ($carousel.length > 0) {
            let smallCaptions = $('.swiper-pagination', $scope).data('caption');
            let mySwiper;

            if(mySwiper == undefined){
                if ($(window).width() >= 768) {
                    mySwiper = new Swiper($carousel.get(0), {
                        loop: false,
                        autoHeight: false,
                        spaceBetween: 0,
                        slidesPerView: 1,
                        mousewheel: true,
                        parallax: true,
                        speed: 800,
                        keyboard: true,
                        pagination: {
                            el: '.swiper-pagination',
                            type: 'bullets',
                            clickable: true,
                            renderBullet: function renderBullet(index, className) {
                                return '<div class="' + className + '">' + '<span class="caption">' + smallCaptions[index] + '</span>' + '<span class="counter">' + ('0' + (index + 1)).slice(-2) + '</span></div>';
                            }
                        },
                    });

                    mySwiper.on('slideChange', function () {
                        let container = $('.swiper-slide', $scope).get(mySwiper.realIndex);
                        let $container = $(container);

                        Elementor_Template_Carousel.getInstance().counter($container);
                        Elementor_Template_Carousel.getInstance().progress($container);
                        Elementor_Template_Carousel.getInstance().animate($container);
                        Elementor_Template_Carousel.getInstance().hotspotAnimation($container);
                    });

                }
            }

            $(window).resize(function () {
                if ($(window).width() < 768) {
                    if(mySwiper != undefined){
                        mySwiper.destroy(true, true);
                    }
                }
            })
        }
    }

    counter($container) {
        let $number = $container.find('.elementor-counter-number');
        if ($number.length > 0) {
            $number.each((index, element) => {
                let data = $(element).data();
                let decimalDigits = data.toValue.toString().match(/\.(.*)/);
                if (decimalDigits) {
                    data.rounding = decimalDigits[1].length;
                }
                $(element).numerator(data);
            });
        }
    }

    progress($container) {
        let $progressbar = $container.find('.elementor-progress-bar');
        if ($progressbar.length > 0) {
            $progressbar.each((index, element) => {
                $(element).css('width', $(element).data('max') + '%');
            });

        }
    }

    hotspotAnimation($container) {
        let $element = $container.find('.opal-image-hotspots-container');

        if ($element.length > 0) {
            $element.removeClass('animation-start');
            setTimeout(function () {
                $element.addClass('animation-start');
            },100);
        }
    }
    animate($container) {
        let $element = $container.find('[data-settings*="animation"]');
        if ($element.length > 0) {
            $element.each((index, element) => {
                let $settings = $(element).data('settings'),
                    animation = $settings['_animation'],
                    animationDelay = typeof $settings['_animation_delay'] === 'undefined' ? 700 : $settings['_animation_delay'];
                if ($(element).hasClass('animated')) {
                    $(element).removeClass(animation);
                    if ($(element).hasClass('elementor-invisible')) {
                        setTimeout(function () {
                            $(element).removeClass('elementor-invisible').addClass(animation);
                        }, animationDelay);
                    } else {
                        $(element).addClass('elementor-invisible');
                        setTimeout(function () {
                            $(element).removeClass('elementor-invisible').addClass(animation);
                        }, animationDelay);
                    }
                }

            });
        }
    }
}


Elementor_Template_Carousel.getInstance();