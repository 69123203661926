class Elementor_Service{
    static instance;

    static getInstance() {
        if (!Elementor_Service.instance) {
            Elementor_Service.instance = new Elementor_Service();
        }
        return Elementor_Service.instance;
    }
    constructor(){
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init(){
        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-services.default', ( $scope ) => {
        let $hover = $scope.find('.elementor-service-wrapper');
        let $slide = $scope.find('.elementor-service-content-inner');
        $hover.hover(function () {
            $slide.slideDown(300);
        },function () {
            $slide.slideUp(300);
        })
        } );
    }
}


Elementor_Service.getInstance();